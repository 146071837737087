import React, { useEffect } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "./CoolStuffComp/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import Footer from '../component/footer/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './PortfolioLanding.css';


const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

const clients = [
    {
        title: 'Zach Martin',
        category: 'ATX Float',
        images: '/assets/images/client/ZachMartin.png',
        testimonial: ''
    },
    {
        title: 'Idaliz Gonzalez',
        category: 'Synecron',
        images: '/assets/images/client/Idaliz5.png',
        testimonial: ''
    },
    {
        title: 'William Hasting',
        category: 'Angel Aerial',
        images: '/assets/images/client/WillHasting.png',
        testimonial: ''
    },
]

const fieldItems = [
    'React/React Native',
    'Angular',
    'Python',
    'Wordpress',
    'Salesforce Dev/Admin',
    'Blockchain Development'
  ];

  function shuffleArray(array) {
    // Fisher-Yates shuffle algorithm
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

const PortfolioLanding = () => {
    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration in milliseconds
          easing: 'ease-out-cubic', // Easing function
          once: true, // Whether animation should happen only once
          disable: 'phone', // Disable animations on mobile devices
        });
      }, []);
    const randomizedItems = shuffleArray([...fieldItems]);

    let title = 'About Me',
        description = 'I\'m Morgan Hondros, a passionate web engineer currently contributing my skills and expertise at ATX Float. In the dynamic realm of web development, I bring a blend of technical proficiency and creative innovation to the table. My commitment to staying at the forefront of emerging technologies ensures that I deliver cutting-edge solutions in our fast-paced environment. With a track record of successful project implementations and a natural knack for problem-solving, I thrive on the challenges presented by the ever-evolving world of web engineering. Known for my strong work ethic and genuine enthusiasm for the craft, I play a significant role in the success and growth of ATX Float\'s web development initiatives.';
    const PostList = clients.slice(0 , 3);
    return (
        <div className="active-dark">
            <Helmet pageTitle="" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title" data-aos="fade-left" >I’m Morgan Hondros <br/>
                                            <TextLoop>
                                            {randomizedItems.map((item, index) => (
                                                // <span key={index}>{item}</span>
                                                <span key={index} className={`inner ${value.textPosition}`}>
  {item}
</span>
                                            ))}
                                            </TextLoop>{" "}
                                            </h1>
                                            <h2 data-aos="fade-right" >based in Columbus, OH/Orlando, FL.</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                    <div className="image-container" data-aos="fade-right" >
                                        <img
                                            src="/assets/images/bg/vecteezy_businessmen-fingerprint-scanning-and-biometric_17585561.jpg"
                                            alt="About Images"
                                            className="about-image"
                                        />
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title" data-aos="fade-left">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30" data-aos="fade-up">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">Services Offered</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            {/* End Service Area  */} 

            {/* Start Portfolio Area */}
            <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Latest Projects</h2>
                                        {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--10" column="col-lg-6 col-md-6 col-sm-6 col-12" item="2" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start Blog Area */}
            <div id="blog" className="fix">
                <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>Client Testimonials</h2>
                                    {/* <p>There are many variations of passages of Lorem Ipsum available, <br />but the majority have suffered alteration.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail" >
                                            <a href="/blog-details">
                                                {/* <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/> */}
                                                <img className="w-100" style={{maxHeight: '350px'}} src={`${value.images}`} alt="Blog Images"/>
                                    
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.category}</p>
                                            <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                            <div className="blog-btn">
                                                {/* <a className="rn-btn text-white" href="/blog-details">Read More</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
            </div>
            {/* End Blog Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    {/* <ContactThree contactImages="/assets/images/about/about-9.jpg" contactTitle="Hire Me." /> */}
                    <div className="container">
                                <div className="row">
                    <div className="col-lg-5" data-aos="fade-right" >
                    <ContactThree contactTitle="Hire Me." />
                    </div>
                    <div className="col-lg-7">
                                    <div className="thumbnail" >
                                    <div className="image-container" data-aos="fade-left" >
                                        <img

                                            src="/assets/images/bg/bg-image-12.jpg"
                                            alt="About Images"
                                            className="about-image"
                                            
                                        />
                                    </div>
                                    </div>
                                </div>
                    </div>
                    </div>
                </div>
            </div>
            {/* End COntact Area */}

            {/* <FooterTwo /> */}
            <Footer/>
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioLanding;
