import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn, FaGithub} from "react-icons/fa";
import Particles from "react-tsparticles";

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/morgan.hondros'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/morgan-hondros-781878151/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/skursion/'},
    {Social: <FaGithub /> , link: 'https://github.com/mghondo'},
    // {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class Footer extends Component{
    render(){
        const currentYear = new Date().getFullYear();
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                
                                <div className="footer-left">
                                <div className="frame-layout__particles">
                        <Particles className="particle"  
                            options={{
                                style:{
                                    position: "absolute"
                                },
                                fpsLimit: 100,
                                interactivity: {
                                detectsOn: "canvas",
                                events: {
                                    onClick: {
                                    enable: true,
                                    mode: "push",
                                    },
                                    onHover: {
                                    enable: true,
                                    mode: "repulse",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    bubble: {
                                        distance: 100,
                                        duration: 2,
                                        opacity: 1,
                                        size: 10,
                                        color: "#888",
                                    },
                                    push: {
                                    quantity: 4,
                                    },
                                    repulse: {
                                        distance: 100,
                                        duration: 0.4,
                                        color: "#FFFFFF",
                                    },
                                },
                                },
                                particles: {
                                color: {
                                    value: "#FFFFFF",
                                },
                                links: {
                                    color: "#FFFFFF",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outMode: "bounce",
                                    random: false,
                                    speed: 6,
                                    straight: false,
                                   
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        value_area: 2000,
                                    },
                                    value: 80,
                                },
                                opacity: {
                                    value: 1,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    random: true,
                                    value: 5,
                                },
                                },
                                detectRetina: true,
                            }}
                        />
                    </div>
                                    <div className="inner">
                                    
                                        <span>Ready To Do This</span>
                                        <h2>Let's get <br /> to work</h2>
                                        {/* <a className="rn-button-style--2" href="/contact">
                                            <span>Contact Us</span>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                {/* <h4>Quick Link</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/portfolio">Work</a></li>
                                                    <li><a href="/about">About</a></li>
                                                    <li><a href="/contact">Let's Talk</a></li>
                                                </ul> */}
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    {/* <li><a href="mailto:morgan@hondros-co.com">morgan@hondros-co.com</a></li> */}
                                                    <l1><img  data-aos="zoom-in" src="/assets/images/about/email.png" alt="Example" /></l1>
                                                    
                                                    {/* <li><a href="tel:614-288-8442">614-288-8442</a></li> */}

                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                            <p>Copyright © {currentYear} Morgan Hondros. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;