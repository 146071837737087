import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor, } from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiMonitor />,
        title: 'React / React Native',
        description: 'Experienced in building efficient and scalable web applications using React, optimizing user interfaces for seamless user experiences.'
    },
    {
        icon: <FiMonitor />,
        title: 'Angular',
        description: 'Proficient in developing dynamic and feature-rich applications with Angular, leveraging the framework\'s powerful capabilities for robust solutions.'
    },
    {
        icon: <FiMonitor />,
        title: 'WordPress',
        description: 'Skilled in customizing WordPress websites, extending functionalities through plugins, and ensuring responsive designs for optimal user engagement.'
    },
    { 
        icon: <FiMonitor />,
        title: 'Salesforce Developer/Admin',
        description: 'Expertise in integrating web applications with Salesforce, leveraging APIs and ensuring seamless data flow between different platforms.'
    },
    {
        icon: <FiMonitor />,
        title: 'Blockchain Developer',
        description: 'I am responsible for researching, designing, developing, and testing blockchain technologies and applications. My key responsibilities include creating the protocol for blockchain technology, setting up a network security pattern, and developing consensus mechanisms, smart contracts, and decentralized applications (dApps).'
    },
    { 
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'Experienced in developing cross-platform mobile applications, ensuring compatibility with various devices and delivering a consistent user experience.'
    }
];



class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="/service-details"> */}
                            <a>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
