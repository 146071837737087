import React, { useState } from 'react';
import './SlotMachine.css';
import { Helmet } from 'react-helmet';
import HeaderFour from '../../component/header/HeaderFour';

const SlotMachine = () => {
  const [isSpinning, setIsSpinning] = useState(true);

  const handleClick = () => {
    if (isSpinning) {
      setIsSpinning(false);
      setTimeout(() => {
        document.querySelector('.slots').classList.add('stop');
      }, 1);
    } else {
      document.querySelector('.slots').classList.remove('stop');
      setIsSpinning(true);
    }
  };

  return (
    <>
      <Helmet pageTitle="" />
      <HeaderFour homeLink="/" logo="symbol-dark" color="color-black"/>
      <div className="slot-main">
        <div className={`slots ${isSpinning ? 'loop' : ''}`}>
          <div className="slot slot1">
            <div className="slot-inner background1"></div>
          </div>
          <div className="slot slot2">
            <div className="slot-inner background1"></div>
          </div>
          <div className="slot slot3">
            <div className="slot-inner background1"></div>
          </div>
        </div>
        <button className="btn2" onClick={handleClick}>
          {isSpinning ? 'HIT IT!' : 'RESTART'}
        </button>
      </div>
    </>
  );
};

export default SlotMachine;