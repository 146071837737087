import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        imageLink: "/assets/images/bg/ATXFloatPage.png",
        photolink: 'http://atxfloat.netlify.app/',
        category: 'ATX Float',
        title: 'Water Sport Wholesale Provider'
    },
    {
        image: 'image-2',
        imageLink: "/assets/images/bg/angelaerial.png",
        photolink: 'https://www.angelaerialsystems.com',
        category: 'Angel Aerial Systems',
        title: 'Drone Engineering Firm'
    },
    {
        image: 'image-3',
        imageLink: "/assets/images/bg/ATXFloatPage.png",
        photolink: '',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        imageLink: "/assets/images/bg/ATXFloatPage.png",
        photolink: '',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-3',
        imageLink: "/assets/images/bg/ATXFloatPage.png",
        photolink: '',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'image-4',
        imageLink: "/assets/images/bg/ATXFloatPage.png",
        photolink: '',
        category: 'Development',
        title: 'Getting tickets to the big show'
    }
]
class PortfolioList extends Component {
    render() {
        const { column, styevariation } = this.props;
        const list = PortfolioListContent.slice(0, this.props.item);

        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div
                            className={`portfolio ${styevariation}`}
                            style={{ backgroundImage: `url(${value.imageLink})`, backgroundPosition: 'top' }}
                        >
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`} style={{ backgroundImage: `url(${value.imageLink})`, backgroundPosition: 'top'  }}></div>
                                {/* No need for bg-blr-image if using background-image */}
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <h4>{value.category}</h4>
                                    <h4>
                                        <a href={value.photoLink} target="_blank">
                                            {value.title}
                                        </a>
                                    </h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" target="_blank" href={value.photolink}>
                                            View Details
                                        </a>
                                    </div>
                                </div>
                            </div>
              {/* Use a regular anchor for external links */}
              <a className="link-overlay" target="_blank" href={value.photolink} rel="noopener noreferrer"></a>
            </div>
                    </div>
                ))}
            </React.Fragment>
        );
    }
}

export default PortfolioList;
