import React, { useState, useEffect } from 'react';
import './Blackjack.css';
import { Helmet } from 'react-helmet';
import HeaderFour from '../../../component/header/HeaderFour';

const Card = ({ value, suit }) => (
  <div className="card" data-value={value} data-suit={suit}>
    {value} of {suit}
  </div>
);

const Blackjack = () => {
  const [deck, setDeck] = useState([]);
  const [playerHand, setPlayerHand] = useState([]);
  const [dealerHand, setDealerHand] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [message, setMessage] = useState('');

  const suits = ['Hearts', 'Diamonds', 'Clubs', 'Spades'];
  const values = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];

  useEffect(() => {
    initializeDeck();
  }, []);

  const initializeDeck = () => {
    let newDeck = [];
    for (let suit of suits) {
      for (let value of values) {
        newDeck.push({ value, suit });
      }
    }
    setDeck(shuffleDeck(newDeck));
  };

  const shuffleDeck = (deck) => {
    for (let i = deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [deck[i], deck[j]] = [deck[j], deck[i]];
    }
    return deck;
  };

  const startGame = () => {
    if (deck.length < 4) initializeDeck();
    const playerCards = [drawCard(), drawCard()];
    const dealerCards = [drawCard(), drawCard()];
    setPlayerHand(playerCards);
    setDealerHand(dealerCards);
    setGameOver(false);
    setMessage('');
  };

  const drawCard = () => {
    const card = deck.pop();
    setDeck([...deck]);
    return card;
  };

  const hit = () => {
    if (!gameOver) {
      const newPlayerHand = [...playerHand, drawCard()];
      setPlayerHand(newPlayerHand);
      if (calculateHandValue(newPlayerHand) > 21) {
        setGameOver(true);
        setMessage('Player busts! Dealer wins.');
      }
    }
  };

  const stand = () => {
    if (!gameOver) {
      let newDealerHand = [...dealerHand];
      while (calculateHandValue(newDealerHand) < 17) {
        newDealerHand.push(drawCard());
      }
      setDealerHand(newDealerHand);
      const playerValue = calculateHandValue(playerHand);
      const dealerValue = calculateHandValue(newDealerHand);
      setGameOver(true);
      if (dealerValue > 21 || playerValue > dealerValue) {
        setMessage('Player wins!');
      } else if (dealerValue > playerValue) {
        setMessage('Dealer wins!');
      } else {
        setMessage('It\'s a tie!');
      }
    }
  };

  const calculateHandValue = (hand) => {
    let value = 0;
    let aceCount = 0;
    for (let card of hand) {
      if (card.value === 'A') {
        aceCount++;
        value += 11;
      } else if (['K', 'Q', 'J'].includes(card.value)) {
        value += 10;
      } else {
        value += parseInt(card.value);
      }
    }
    while (value > 21 && aceCount > 0) {
      value -= 10;
      aceCount--;
    }
    return value;
  };

  return (
    <div className="blackjack-game">
            <Helmet pageTitle="" />
      <HeaderFour homeLink="/" logo="symbol-dark" color="color-black"/>
      <h1>Blackjack</h1>
      <button onClick={startGame}>New Game</button>
      <div className="hands">
        <div className="player-hand">
          <h2>Player's Hand ({calculateHandValue(playerHand)})</h2>
          {playerHand.map((card, index) => (
            <Card key={index} value={card.value} suit={card.suit} />
          ))}
        </div>
        <div className="dealer-hand">
          <h2>Dealer's Hand ({gameOver ? calculateHandValue(dealerHand) : '?'})</h2>
          {dealerHand.map((card, index) => (
            gameOver || index === 0 ? (
              <Card key={index} value={card.value} suit={card.suit} />
            ) : (
              <div key={index} className="card back">?</div>
            )
          ))}
        </div>
      </div>
      <div className="actions">
        <button onClick={hit} disabled={gameOver}>Hit</button>
        <button onClick={stand} disabled={gameOver}>Stand</button>
      </div>
      {message && <div className="message">{message}</div>}
    </div>
  );
};

export default Blackjack;