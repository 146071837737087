import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Web",
        tab2 = "SalesForce",
        tab3 = "Experience",
        tab4 = "Education";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                        Front-end Development: React, JavaScript, HTML5, CSS3, Redux, Webpack, Wordpress, Angular
                                            <ul>
                                                <li>
                                                    {/* <a href="/service">User experience design <span> - UI/UX</span></a> */}
                                                    React, React-Native, Java, JavaScript, HTML5, CSS3, Redux, Webpack, Wordpress, Angular.
                                                </li>
                                                <li>
                                                    {/* <a href="/service">Web and user interface design<span> - Development</span></a> */}
                                                    Back-end Technologies: Node.js, Express, MongoDB
                                                </li>
                                                <li>
                                                    {/* <a href="/service">Interaction design <span> - Animation</span></a> */}
                                                    UI Frameworks: Material-UI, Ant Design
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   {/* <a href="/service">Awwwards.com <span>- Winner</span></a> 2019 - 2020 */}
                                                   Proficient in configuring Salesforce to meet business requirements.
                                               </li>
                                               <li>
                                                   {/* <a href="/service">CSS Design Awards <span>- Winner</span></a> 2017 - 2018 */}
                                                   Expertise in setting up custom objects, fields, workflows, and process builder to automate business processes.
                                               </li>
                                               <li>
                                                   {/* <a href="/service">Design nominees <span>- site of the day</span></a> 2013- 2014 */}
                                                   Knowledgeable in managing page layouts, record types, and data validation rules for optimal user experience.
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="https://www.atxfloat.netlify.app/" target="_blank">Sr. Front-end Engineer<span> - ATX Float </span></a> 
                                                   April 2023 - Present
                                               </li>
                                               <li>
                                                   {/* <a href="">Front-end Engineer<span> - Microsoft</span></a>  */}
                                                   Led the complete redesign of the company's website using React
                                               </li>
                                               <li>
                                                   {/* <a href="/service">Software Engineer<span> - Alibaba </span></a> 2013- 2014 */}
                                                   Collaborated with a cross-functional team to ensure all objects for the revamped website, meeting project goals and deadlines.
                                               </li>
                                           </ul>
                                           ATX Float (April 2023 - Present)
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="">Master of Business Administration (MBA)<span> - Fisher College of Business
</span></a> 2009 - 2011
                                               </li>
                                               <li>
                                                   <a href="/service">Bachelor of Business Administration<span> University of Kansas</span></a> 1999 - 2004
                                               </li>
                                               <li>
                                                   <a href="/service"><span> - St. Charles Preparatory (High School)</span></a> 1995 - 1999
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;